import { ForumApi } from '../../api/forum'

const forumApi = new ForumApi()

const state = {}

const actions = {
  async getAllTalks({ commit }, params) {
    const res = await forumApi.getAllTalks(params)
    return res
  },

  async createForum({ commit }, body) {
    const res = await forumApi.create(body)
    return res
  },

  async createComment({ commit }, body) {
    const res = await forumApi.createComment(body)
    return res
  }

}

const mutations = {}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
