<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/index.vue";
export default {
  components: { PageHeader },
  data: () => ({}),
  mounted() {
    if (this._isMobile()) {
      console.log('手机端');
    } else {
      console.log('PC端');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
};
</script>


<style scoped>
@import "./assets/CSS/common.css";
</style>
