import axios from './index'

export class UserApi {

  doLogin(data) {
    return axios.post('/users/login', data)
  }

  doRegister(data) {
    return axios.post('/users/register', data)
  }

  dotest(params) {
    return axios.get('/users/test', params)
  }

  activation(params) {
    return axios.get('/users/activation', {params})
  }

  sender(params) {
    return axios.get('/users/sendEmail', { params })
  }

}