<template>
  <div class="header">
    <div class="content center">
      <div class="logo" @click="backToHome">校内题库论坛平台</div>
      <div class="menu">
        <li v-if="!isLogin" class="no-avaid" @click="toLoginPage">登录</li>
        <el-popover
          v-else
          placement="bottom"
          width="350"
          trigger="hover"
          content=""
        >
          <div class="tips">
            <h1 class="title_h1">{{ userInfo.userName }}</h1>
            <h3>{{ userInfo.email }}</h3>
            <h3>剩余使用次数：{{ userInfo.RemainingTimes }}</h3>
            <h3>上次在线时间：{{ userInfo.lastOnLineTime }}</h3>
            <el-divider />
            <el-button
              type="primary"
              round
              @click="dialogVisible = true"
            >退出登录</el-button>
          </div>
          <li slot="reference" class="avaid">
            {{ userInfo.userName.substr(0, 1) }}
          </li>
        </el-popover>
        <li>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              各科题库<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">英语</el-dropdown-item>
              <el-dropdown-item command="e" divided>全部预览</el-dropdown-item>
              <!-- <el-dropdown-item command="b">狮子头</el-dropdown-item>
              <el-dropdown-item command="c">螺蛳粉</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li class="dev-info" @click="toPage('Dep')">关于开发者</li>
        <!-- <li>第三</li> -->
      </div>

      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定需要退出登录吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="singOut">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getInfo, removeToken, removeInfo } from '../../tools/auth'
export default {
  name: 'PageHeader',
  data: () => ({
    userInfo: getInfo(),
    isLogin: false,
    dialogVisible: false
  }),
  mounted() {
    if (getInfo()) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }
  },
  methods: {
    toLoginPage() {
      this.$router.push('/Login')
    },
    backToHome() {
      this.$router.push({ path: '/' })
    },
    goToDev() {
      // console.log(111);
      this.$router.push('/Developer')
    },
    toPage(name) {
      this.$router.push({ name: name })
    },
    singOut() {
      this.isLogin = false
      removeToken()
      removeInfo()
      this.userInfo = getInfo()
      this.dialogVisible = false
      this.$message({
        message: '退出账号成功',
        type: 'success'
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    handleCommand(command) {
      // this.$message("click on item " + command);
    }
  }
}
</script>

<style scoped>
@import "../../assets/CSS/common.css";
.header {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #d0d0d0; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.logo {
  color: skyblue;
  font-size: 1.5em;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700;
  cursor: pointer;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu li {
  padding: 0 10px;
}

.avaid {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(105, 105, 105);
  line-height: 50px;
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  cursor: pointer;
}

.tips {
  width: 330px;
}

.tips .title_h1 {
  color: skyblue;
  text-align: center;
}

.no-avaid {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(105, 105, 105);
  line-height: 50px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 16px;
}
.el-icon-arrow-down {
  font-size: 16px;
}
.dev-info {
  cursor: pointer;
  color: #409eff;
  font-size: 16px;
}
</style>
