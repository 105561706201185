import Vue from 'vue'
import VueRouter from 'vue-router'
import { getInfo } from '@/tools/auth'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Home',
    component: () => import('@/views/Home/index'),
    children: [
      {
        path: '',
        name: 'Welcome',
        component: () => import('@/views/Welcome/index')
      },
      {
        path: '/AllTools',
        name: '/AllTools',
        component: () => import('@/views/AllTools/index')
      },
      {
        path: '/Paper',
        name: 'Paper',
        component: () => import('@/views/Paper/index')
      },
      {
        path: '/Bank',
        name: 'Bank',
        component: () => import('@/views/Bank/index')
      },
      {
        path: '/Forum',
        name: 'Forum',
        component: () => import('@/views/Forum/index')
      },
      {
        path: '/ForumDetail',
        name: 'ForumDetail',
        component: () => import('@/views/Forum/forum.vue')
      },
      {
        path: '/Claws',
        name: 'Claws',
        component: () => import('@/views/Bank/Utils/claws.vue')
      },
      {
        path: '/Usas',
        name: 'USAS',
        component: () => import('@/views/Bank/Utils/usas.vue')
      },
      {
        path: '/Dep',
        name: 'Dep',
        component: () => import('@/views/Developer/index')
      }
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login/index')
  },
  {
    path: '/Activation',
    name: 'Activation',
    component: () => import('@/views/Login/activation.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes
})

// 解决重复路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  const isLogin = getInfo()
  // eslint-disable-next-line no-constant-condition
  if (to.name === 'Welcome' || to.name === 'Dep' || 'Activation') {
    next()
  } else {
    console.log(to)
    if (to.name !== 'Login' && !isLogin) {
      Message({ message: '请先登录再使用该模块哦~', type: 'warning' })
      setTimeout(() => {
        next({ name: 'Login' })
      }, 1000)
    } else next()
  }
})

export default router
