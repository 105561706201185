import { EnglishApi } from '../../api/english'

const englishApi = new EnglishApi()

const state = {}

const actions = {
  async getAllQuestions({ commit }, params) {
    const res = await englishApi.getAllQuestions(params)
    return res
  },

  async addQuestion({ commit }, body) {
    const res = await englishApi.getAllQuestions(body)
    return res
  },

  async updateQuestion({ commit }, body) {
    const res = await englishApi.updateQuestion(body)
    return res
  },

  async delectQuestion({ commit }, body) {
    const res = await englishApi.delectQuestion(body)
    return res
  }

}

const mutations = {}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
