import { SubjectApi } from '../../api/subject'

const subjectApi = new SubjectApi()

const state = {}

const actions = {
  async getSubjectList({ commit }, params) {
    const res = await subjectApi.getSubjectList(params)
    return res
  }

}

const mutations = {}


export default {
  state,
  mutations,
  actions,
  namespaced: true
}