import axios from 'axios'
// import store from '@/store' 
import { MessageBox, Message, Notification } from 'element-ui'
import { 
  getToken, 
  removeInfo, 
  removeToken, 
  setToken,
  setRefreshToken,
  removeRefreshToken,
  getRefreshToken
 } from '../tools/auth'
import router from '../router/index'

const server = axios.create({
  // baseURL: 'http://127.0.0.1:3000', 
  // baseURL: 'https://olbing.cn:3000',
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
})

// 请求拦截器
server.interceptors.request.use(
  config => {
    // if (!getToken()) {
    //   // let cancel
    //   // config.cancelToken = new axios.CancelToken((c) => {
    //   //   cancel = c
    //   // })
    //   Message({ message: `请先登录再使用哦~` || 'Error', type: 'error', duration: 3 * 1000 })
    //   return Promise.reject(1111)
    // } else {
    //   config.headers.Authorization = getToken()
    // }
    config.headers.Authorization = getToken()
    config.headers.refreshToken = getRefreshToken() || null
    return config
  },
  error => {
    return Promise.reject(error)
  })

// 响应拦截器
server.interceptors.response.use(
  response => {
    const res = response.data

    const code = Number(res.code)
    if (code !== 200) {
      if (code === 10101) {
        MessageBox.confirm('登录时间过期，或账号在别处登录，请重新登录哦', '登录时间过期', {
        }).then(() => {
          removeInfo()
          removeToken()
          removeRefreshToken()
          router.push({ path: '/Login' })
        })
        return
      }
      if (code === 10102) {
        Message({ message: '请先登录再使用该功能，如果已经登录，可能为账号已过期，或在别处登录，请重新登录使用哦~', type: 'Error' })
        removeInfo()
        removeToken()
        setTimeout(() => {
          router.push({ path: '/Login' })
        }, 1500);
        return
      }
      if (code === 201) {
        if (res && res.results) {
          const { token, refreshToken } = res.results
          setToken(token)
          setRefreshToken(refreshToken) 
        }
        return res
      }
      Message({ message: res.message + ` 错误码: ${code}` || 'Error', type: 'error', duration: 5 * 1000 })
      return res
    }
    if (res.ResetToken && res.token) {
      setToken(res.token)
      // console.log(111);
    }
    
    // console.log(res, '响应拦截器log');
    return res
  },
  error => {
    // console.error(error);
    return Promise.reject(error)
  })

export default server