import { UtilsApi } from '@/api/utils'
import qs from 'qs'

const utilsApi = new UtilsApi()

const state = {}

const actions = {
  async useClaws({ commit }, body) {
    body = qs.stringify(body)
    const res = await utilsApi.useClaws(body)
    return res
  },
}

const mutations = {

}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}