import axios from './index'

export class EnglishApi {
  getAllQuestions(params) {
    // console.log(params);
    return axios.get('/english/getAllQuestions', { params })
  }

  addQuestion(data) {
    return axios.post('english/addQuestion', data)
  }

  updateQuestion(data) {
    return axios.post('/english/updateQuestion', data)
  }

  delectQuestion(data) {
    return axios.post('/english/delectQuestion', data)
  }
}
