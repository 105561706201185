import axios from './index'

export class Baidu {
  // getFileList (params) {
  //   params.method = 'list'
  //   return axios.get('https://pan.baidu.com/rest/2.0/xpan/file', { params })
  // } 
  translate(params) {
    return axios.get('api/trans/vip/translate', { params })
  }
}