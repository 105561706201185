import axios from 'axios'

const config = {
  headers: { 'Content-Type': 'multipart/form-data' }
}

export class UtilsApi {

  useClaws(data) {
    return axios.post('/cgi-bin/claws74.pl', data, config)
    // return axios.post('http://ucrel-api.lancaster.ac.uk/cgi-bin/claws74.pl', data)
  }

}