import { TransApi } from '../../api/trans'

const transApi = new TransApi()

const state = {}

const actions = {

  async doTrans({ commit }, body) {
    const res = await transApi.doTrans(body)
    return res
  }

}

const mutations = {}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}