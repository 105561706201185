import { Baidu } from '@/api/baidu'
import { UserApi } from '@/api/user'
const userApi = new UserApi()


// const user = new User()
const baidu = new Baidu

const state = {
  // appid: '20201214000647167',
  // key: 'CegpWBe7kNAxJOF1Ei3T',
  name: '',
  token: ''
}

const actions = {
  async translate({ commit }, params) {
    const { data } = await baidu.translate(params)
    return data
  },

  async doLogin({ commit }, body) {
    const res = await userApi.doLogin(body)
    return res
  },

  async doRegister({ commit }, body) {
    const res = await userApi.doRegister(body)
    return res
  },

  async doTest({ commit }, params) {
    const res = await userApi.dotest(params)
    return res
  },

  async activation({commit}, params) {
    const res = await userApi.activation(params) 
    return res
  },

  async sender({ commit }, params) {
    const res = await userApi.sender(params)
    return res
  }
}

const mutations = {
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}