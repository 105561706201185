import axios from './index'

export class ForumApi {
  create(data) {
    return axios.post('/talks/createTalk', data)
  }

  getAllTalks(params) {
    return axios.get('/talks/getAllTalks', { params })
  }

  createComment(data) {
    return axios.post('/Comments/createComment', data)
  }
}
